<template>
    <v-container fluid>
        <!-- pageHeader -->
        <v-row align="center" class="mb-4" dense>
            <v-col cols="12" md="8">
                <PageTitle>Asistentes Especiales</PageTitle>
            </v-col>
            <v-col cols="12" md="4" class="text-end">
                <v-btn :block="appMobile" color="primary" class="px-12" large @click="dialog = true" depressed>
                    Agregar Asistente
                </v-btn>
            </v-col>
        </v-row>

        <!-- loading -->
        <div v-if="isLoading"> <v-skeleton-loader v-for="n in 10" :key="n" type="list-item-avatar-two-line"
                class="mb-3" />
        </div>

        <!-- card -->
        <v-card v-for="item, index in items" :key="index" class="mb-3" flat>
            <v-list-item two-line>
                <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ item.uid }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn :loading="item.loading" @click="onRemove(index, item.uid)" icon color="error">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-linecap="round" stroke-linejoin="round" width="24" height="24" stroke-width="1.75">
                            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5"></path>
                            <path d="M22 22l-5 -5"></path>
                            <path d="M17 22l5 -5"></path>
                        </svg>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-card>

        <CreateDialog v-model="dialog" @result="onResult" />
    </v-container>
</template>

<script>
import { getAllVipAttendees, removeAttendeeVip } from "@/api/attendees";
import CreateDialog from './VipCreate.vue'
import PageTitle from '@/components/layout/PageTitle.vue';

export default {
    components: { CreateDialog, PageTitle },

    data: () => ({
        items: [],
        dialog: false,
        isLoading: false,
    }),

    methods: {
        onResult(params) {
            this.items.push({ uid: params, vip: true, loading: false })
        },

        async onRemove(index, uid) {
            if (confirm('¿Estás seguro de querer quitar privilegios a este asistente?')) {
                try {
                    this.items[index].loading = true;
                    await removeAttendeeVip(uid);
                    this.items.splice(index, 1);
                } catch (error) {
                    console.error(error)
                    this.items[index].loading = false;
                }
            }
        },

        async fetchData() {
            try {
                this.isLoading = true;
                this.items = await getAllVipAttendees();
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false;
            }
        }
    },

    mounted() {
        this.fetchData();
    },
}
</script>